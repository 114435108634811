export const usePageState = () => {
	const unsavedChangesStack = useState<{ [key: string]: boolean }>(
		"unsavedChangesStack",
		() => ({}),
	);
	const unsavedChanges = computed(() => {
		const hasUnsavedChanges = Object.values(unsavedChangesStack.value).includes(
			true,
		);
		useState<boolean>("uncommittedChanges").value = hasUnsavedChanges;
		return hasUnsavedChanges;
	});
	const syncing = useState("syncing");
	return {
		unsavedChanges,
		syncing,
	};
};
